import React from "react"
import Section from "@components/section"
import { Box } from "rebass"
import MobileDownloadCTA from "@components/MobileDownloadCTA"
import DesktopDownloadForm from "@components/mailchimpFormDesktopDownload.js"

const UnleashThePower = ({ data }) => (
  <React.Fragment>
    <div
      style={{
        textAlign: "center",
        marginTop: "122px",
        fontSize: "2rem",
        fontWeight: "600",
        lineHeight: "1.3",
        letterSpacing: -1,
      }}
      id="features"
    >
      How Dynobase Helps in Single-Table Modeling
    </div>
    <Section
      title="Planning Access Patterns"
      desc="Planning how you're going to query your is essential in a single-table design. Dynobase helps you to design your access patterns in a WYSIWYG way."
      imageName="access-patterns.png"
      allImageSharpNodes={data.allImageSharp.nodes}
    />
    <Section
      title="Designing Your Items"
      desc="Creating new items is as easy as editing an Excel file. Navigate through table space like through a spreadsheet. Use key bindings you're familiar with from other programs like 'Tab' to cycle through the cells."
      imageName="designing-items.png"
      allImageSharpNodes={data.allImageSharp.nodes}
    />
    <Section
      title="Smart Suggestions"
      desc="It's easy to get lost in the data. Dynobase helps you make sure you're making correct decisions and proactively suggests you to think in collections."
      imageName="smart-suggestions.png"
      allImageSharpNodes={data.allImageSharp.nodes}
    />
    <Section
      title="Models View"
      desc="Big single-table models can quickly get overwhelming. Get a bird's-eye view of your model from a different perspective."
      imageName="models-view.png"
      allImageSharpNodes={data.allImageSharp.nodes}
    />
    <Section
      title="Exporting Models"
      desc="Once you're satisfied with your design, you can export it to popular libraries format like OneTable or DynamoDB-Toolbox."
      imageName="exporting-models.png"
      allImageSharpNodes={data.allImageSharp.nodes}
    />
    <Section
      title="Referential Integrity"
      desc="Composite keys are a must in a single-table design. Dynobase helps you in creating composite keys and makes sure they are always up to date, referring to other attributes."
      imageName="referential-integrity.png"
      allImageSharpNodes={data.allImageSharp.nodes}
    />
    <Section
      title="Ready, Steady, Deploy!"
      desc="All set? Dynobase will help you to deploy your DynamoDB Table to AWS Cloud. Using Infrastructure as Code? Dynobase will export a CloudFormation definition."
      imageName="deploy-to-aws.png"
      allImageSharpNodes={data.allImageSharp.nodes}
    />
    <p
      style={{
        textAlign: "center",
        color: "rgba(0,0,0,0.8)",
        fontSize: "1.2em",
        fontWeight: 700,
        marginTop: 100,
        marginBottom: 0,
      }}
    >
      Still not convinced? Try it on your own.
    </p>
    <p
      style={{
        textAlign: "center",
        color: "rgba(0,0,0,0.8)",
        fontSize: "0.8em",
        fontWeight: 400,
        marginBottom: 20,
      }}
    >
      First 7 days are on us, no strings attached.
    </p>
    <MobileDownloadCTA />
    <Box display={["none", "none", "block"]} mb={3}>
      <DesktopDownloadForm primary data={data} />
    </Box>
    <p
      style={{
        textAlign: "center",
        color: "rgba(0,0,0,0.7)",
        fontSize: "0.7em",
        lineHeight: 1.3,
        marginTop: "2px",
        marginBottom: "32px",
      }}
    >
      <span style={{ marginRight: "16px" }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="10"
          height="10"
          viewBox="0 0 24 24"
          style={{ marginBottom: "-1px" }}
        >
          <path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z" />
        </svg>{" "}
        7-day free trial
      </span>
      <span style={{ marginRight: "16px" }}>
        {" "}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="10"
          height="10"
          viewBox="0 0 24 24"
          style={{ marginBottom: "-1px" }}
        >
          <path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z" />
        </svg>{" "}
        No credit card required
      </span>
      <span style={{ marginRight: "0" }}>
        {" "}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="10"
          height="10"
          viewBox="0 0 24 24"
          style={{ marginBottom: "-1px" }}
        >
          <path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z" />
        </svg>{" "}
        Available for macOS, Linux and Windows
      </span>
    </p>
  </React.Fragment>
)

export default UnleashThePower
