import React from "react"
import { Box } from "rebass"
import { FooterWithCTA as Footer, Container, SEO, Layout } from "@components"
import TenThings from "@components/pages/single-table-design-tool/TenThings"
import Testimonials from "@components/pages/home/Testimonials"
import Hero from "@components/pages/single-table-design-tool/Hero"
import { graphql } from "gatsby"
import FAQJsonLD from "@components/FAQJsonLD"

const IndexPage = ({ data }) => {
  return (
    <>
      <div
        style={{
          position: "absolute",
          backgroundColor: "rgb(24, 144, 255)",
          overflow: "hidden",
          width: "100%",
          height: "850px",
          msTransform: "skewY(-6deg)",
          transform: "skewY(-6deg)",
          WebkitTransform: "skewY(-6deg)",
          top: -300,
          zIndex: -1000,
        }}
      >
        {" "}
      </div>
      <div
        style={{
          position: "absolute",
          backgroundColor: "rgb(24, 144, 255, 0.3)",
          overflow: "hidden",
          width: "100%",
          height: "860px",
          msTransform: "skewY(-6deg)",
          transform: "skewY(-6.6deg)",
          WebkitTransform: "skewY(-6.2deg)",
          top: -280,
          zIndex: -1000,
        }}
      >
        {" "}
      </div>
      <Layout>
        <SEO
          title="DynamoDB Single Table Design Made Easy [Visual Tool]"
          description="Dynobase makes DynamoDB Single Table Design easy. Plan access patterns, use starter templates/examples with baked-in best practices, and much more."
          canonical="https://dynobase.dev/dynamodb-single-table-design-tool/"
        />
        <FAQJsonLD
          questions={[
            {
              question: "What is DynamoDB Single Table Design?",
              answer:
                "Single-Table Design is a data modeling technique used to represent relational data structures inside a single NoSQL table in DynamoDB. It is about putting all your data in a smart and organized way into one table (not one table per entity type!). Use a combination of primary key and indexes to link the item together and access them in a fast and efficient way.",
            },
            {
              question: "Why is Single Table Design popular in DynamoDB?",
              answer: `Single-table design allows you to minimize the number of tables you have to create, thereby minimizing your infrastructure's operational burden. Instead of scaling, managing, and keeping backups of multiple tables, you can just focus on one.`,
            },
            {
              question: "How to design Single Table Models in DynamoDB?",
              answer: `Planning is essential in a single-table design. You need to know what your data is, how it is related to each other, and most importantly, what your access patterns are - they will dictate how you should design your items. Dynobase helps with that. Our single-table designer allows you to create a schema for your data, plan access patterns and then use it to create a table and export its definition.`,
            },
          ]}
        />
        <Container>
          <Hero data={data} />
          <TenThings data={data} />
          <Testimonials data={data} />

          <h3 style={{ textAlign: "center", marginTop: 120, marginBottom: 5 }}>
            Frequently Asked Questions
          </h3>
          <Box p={["0 20px", "0 60px", "0 60px"]} style={{ marginTop: "62px" }}>
            <h4>Why is Single Table Design popular in DynamoDB?</h4>
            <p>
              Single-table design allows you to minimize the number of tables
              you have to create, thereby minimizing your infrastructure's
              operational burden. Instead of scaling, managing, and keeping
              backups of multiple tables, you can just focus on one.
            </p>
            <h4>How to design Single Table Models in DynamoDB?</h4>
            <p>
              Planning is essential in a single-table design. You need to know
              what your data is, how it is related to each other, and most
              importantly, what your access patterns are - they will dictate how
              you should design your items. Dynobase helps with that. Our
              single-table designer allows you to create a schema for your data,
              plan access patterns and then use it to create a table and export
              its definition.
            </p>
            <h4>
              Where can I find more information about DynamoDB Single Table
              Design?
            </h4>
            <p>
              We highly recommend the following resources:
              <ul>
                <li>
                  <a
                    href="https://www.dynamodbbook.com/"
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    DynamoDB Book by Alex DeBrie
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.youtube.com/watch?v=KYy8X8t4MB8"
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    Fundamentals of Single-Table Design with Rick Houlihan
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.youtube.com/watch?v=6yqfmXiZTlM"
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    Amazon DynamoDB Deep Dive: Advanced Design Patterns
                  </a>
                </li>

                <li>
                  <a
                    href="https://www.youtube.com/watch?v=h5wl6uEAVuc"
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    DynamoDB Office Hours - Data Modeling with Dynobase
                  </a>
                </li>
              </ul>
            </p>
          </Box>
        </Container>
        <Footer />
      </Layout>
    </>
  )
}

export const query = graphql`
  query {
    allImageSharp {
      nodes {
        gatsbyImageData(width: 800)
        fluid {
          originalName
        }
      }
    }
  }
`

export default IndexPage
