import React, { useState } from "react"
import { Box } from "rebass"
import MobileDownloadCTA from "@components/MobileDownloadCTA"
import DesktopDownloadForm from "@components/mailchimpFormDesktopDownload.js"
import { getImage, getImageNode } from "../../../utils/getImage"
import { StaticImage } from "gatsby-plugin-image"

const Hero = props => {
  const [isVideo, setVideo] = useState(false)

  return (
    <React.Fragment>
      <h1
        style={{
          color: "white",
          fontWeight: 600,
          fontSize: "2.5rem",
          textAlign: "center",
          marginTop: "62px",
          lineHeight: "1.2",
        }}
      >
        "Dynobase should absolutely be your go to tool for DynamoDB data
        modeling."
      </h1>
      <a
        href="https://twitter.com/houlihan_rick/status/1469808564730531841"
        target="_blank"
        rel="noreferrer noopener"
        style={{
          textDecorationColor: "white",
        }}
      >
        <h5
          style={{
            marginTop: "-15px",
            zIndex: 10000,
            color: "white",
            textAlign: "center",
          }}
        >
          Rick Houlihan
        </h5>
      </a>
      <h6 style={{ marginTop: "-20px", color: "#f5f5f5", textAlign: "center" }}>
        Inventor of Single-Table Design, Director Developer Relations @ MongoDB
      </h6>

      {/* <StaticImage
        src="../../../images/designer.png"
        alt="Single-table designer in Dynobase"
        style={{
          borderRadius: 5,
        }}
      /> */}

      <iframe
        style={{ marginTop: '-80px', marginBottom: '-60px', boxShadow: "rgba(0, 0, 0, 0.15) 3px 4px 10px 4px", marginLeft: '-20%', border: "1px #e5e5e5 solid", backgroundColor: 'white',  borderRadius: 5, transform: 'scale(0.75)', }}
        width="140%"
        height="600px"
        src={`https://viewer.dynobase.dev/?modelUrl=https://raw.githubusercontent.com/Dynobase/single-table-models/main/Movies/Movies.json`}
      />

      <MobileDownloadCTA />
      <Box display={["none", "none", "block"]} mb={3}>
        <DesktopDownloadForm primary data={props.data} />
      </Box>
      <p
        style={{
          textAlign: "center",
          color: "rgba(0,0,0,0.7)",
          fontSize: "0.7em",
          lineHeight: 1.3,
        }}
      >
        7-day free trial. Available for macOS, Linux and Windows
      </p>
      <p
        style={{
          // color: "white",
          fontWeight: 400,
          fontSize: "1rem",
          textAlign: "center",
          marginTop: "40px",
        }}
      >
        <b>Single-Table Design</b> is a data modeling technique used to
        represent relational data structures inside a single NoSQL table in
        DynamoDB. It is about putting all your data in a smart and organized way
        into one table (not one table per entity type!). Use a combination of
        primary key and indexes to link the item together and access them in a
        fast and efficient way.
      </p>
    </React.Fragment>
  )
}

export default Hero
